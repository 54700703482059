$font-color: #000;
.custom-dropdown-box{

    .loading-div,.option-label{
        color: $font-color;
    }
    .option-list{

    }

    .option-item{
        color: $font-color;
        height: 30px;
        padding: 0px 10px;
        margin: 5px;
        cursor: pointer;

    }

    .selected-item{
        background-color: #ccc;
        font-weight: 600;
    }

}