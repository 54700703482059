.site-page-flex{
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 10px;
}

.border-rounded-container {
    border-radius: 10px;
    border: 2px solid #0C71C3;
    padding: 14px;
    background: "white" !important;

}

.rounded-container-flex {
    display: flex;
    gap: 300px;
    flex-direction: "row";
    padding-left: 70px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.rounded-container-title {
    font-size: 28px;
    font-weight: 600;
    color: #0C71C3;
    padding-left: 10px;
}

.aed-unit-container {
    display: flex;
    gap: 0px;
    flex-wrap: wrap;

}

