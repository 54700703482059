#states-modal {
  .modal-container {
    // position: absolute;
    // width: 100%;
    // height: 100vh;
    top: 0;
    background-color: #f1f1f1;
    // display: flex;
    justify-content: center;
    align-items: center;
  }

  .my-modal-section {
    // width: 90%;
    margin: 0px auto;
    font-family: Arial, Helvetica, sans-serif;
    // min-height: 50vh;
    // position: absolute;
    background-color: white;
    // display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    // box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.184);
  }

  h2 {
    margin: 20px 0px;
    font-weight: 600;
  }

  /* upper div  */
  .my-modal-section .upper-div {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    width: 100%;
  }
  .my-modal-section .products,
  .my-modal-section .selected-products {
    width: 40%;
    border: 2px solid #6f9cc2;
    min-height: 400px;
  }

  ul li {
    list-style: none;
    border: 1px solid #6f9cc2;
    padding: 5px 8px;
    font-weight: 700;
    cursor: pointer;
  }

  ul li:nth-child(2n-1) {
    background-color: #e4e4e4;
  }
  ul li.title {
    background-color: #999999;
    font-weight: 700;
  }

  .upper-div .btns {
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }
  .upper-div .btns button {
    // padding: 1px 8px;
    color: white;
    font-weight: 700;
    outline: none;
    border: none;
    margin: 10px 0px;
  }
  .upper-div .btns .select-btn {
    background-color: #fff;
    color: #0c71c3;
    font-size: 50px;
  }
  .upper-div .btns .elemenate-btn {
    background-color: #fff;
    color: #e40000;
    font-size: 70px;
  }

  /* lower div  */
  .lower-div {
    margin: 20px 0px;
  }
  .my-modal-section .lower-div button {
    padding: 8px 10px;
    font-size: 18px;
    font-weight: 600;
    margin: 0px 10px;
    color: white;
    outline: none;
    cursor: pointer;

    border: none;
  }

  .products > ul,
  .selected-products > ul {
    padding: 0px;
    width: 100%;
  }

  .Cancel-btn {
    background-color: #8e0100;
    color: #fff;
    border: none;
    padding: 10px 15px;
  }

  .submit-btn {
    background-color: #59b400;
    color: #fff;
    border: none;
    padding: 10px 15px;
  }
  .checkbox {
    label {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  // input[type="checkbox"]{
  //   display: none;
  // }
}

.checkbox {
  cursor: pointer;
}

// .checkbox input[type="checkbox"]:focus + label::before {
//     border:  10px solid red;
//     outline: rgb(59, 153, 252) auto 5px;
// }
