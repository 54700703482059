
.custom-tab{
    .tab-item .tab-item-a{
        color: #fff !important; 
        border: none !important;
        border-radius: 0 !important;
        column-gap: 2px;
    }
    .tab-item-a:hover{
        color: #fff ;
        background-color: #26aee0 ;
        border-radius: 0 !important;
        column-gap: 2px;
        // margin-right: 2px;
        // margin-left: 2px;
    }

    .tab-item-a.active{
            color: #fff ;
            background-color: rgb(38, 174, 224)  !important;
            border-radius: 0 !important;
            column-gap: 2px;
    }
}