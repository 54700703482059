.heading{
    color: rgba(12, 113, 195, 1);
    font-family: 'Calibri';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 37px;
}

.dx-header-row td{
    background: #999999;
    border-top: 3px solid #0C71C3 ;
    color: black;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 29px;
}
.dx-datagrid-headers .dx-datagrid-table .dx-row > td {
    border-bottom: none;
}
.dx-datagrid-borders .dx-datagrid-rowsview, .dx-datagrid-headers + .dx-datagrid-rowsview, .dx-datagrid-rowsview.dx-datagrid-after-headers {
    border-top: none;
}
.dx-datagrid .dx-column-lines > td , .dx-datagrid .dx-row-alt > td, .dx-datagrid .dx-row-alt > tr > td {
    border-right: 3px solid #0C71C3;
    color: black;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
}
.dx-datagrid .dx-row-alt > td, .dx-datagrid .dx-row-alt > tr > td {
    background-color: #E4E4E4;
    border-top: 1px solid #f5f5f5;
    border-bottom: 1px solid #f5f5f5;
}
.site-title-btn{
    position: relative;
}

.site-title-btn .btn-section{
    position: absolute;
    right: 0px;
    top:0px;
}