#create-new-equipment-form .custom-select-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
}

#create-new-equipment-form .custom-select {
  width: 100%;
  padding: 10px;
  padding-right: 30px; /* Ensure there's space for the arrow */
  border: 1px solid #ced4da;
  border-radius: 4px;
  background: white;
  appearance: none; /* Remove default dropdown arrow */
}

.custom-arrow {
  position: absolute;
  top: 50%;
  right: 10px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #333;
  transform: translateY(-50%);
  pointer-events: none; /* Prevent the arrow from capturing mouse events */
}

/* Optional: Add styles for when the select is focused or active */
#create-new-equipment-form .custom-select:focus {
  border-color: #80bdff;
  outline: none;
}
.shift-left {
  position: relative;
  left: -20px; /* Move the input box 20px to the left, adjust as needed */
}
.table-head-color {
  background-color: #999999 !important;
}
.row-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px; /* Spacing between rows */
}

.NewPopContactField {
  flex: 1 1 calc(50% - 10px); /* 50% width with space between elements */
  margin-right: 10px; /* Spacing between items */
  margin-bottom: 10px; /* Spacing between rows */
}

.NewPopContactField:last-child {
  margin-right: 0; /* Remove margin for the last item */
}
