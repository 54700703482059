#deliquentChart-Section {

    
// DelinquentChecks.css
.delinquent-checks-container {
    padding: 10px;
    background-color: transparent;
    width: 100%;
    // max-width: 600px;
    border: 2px solid black;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .delinquent-checks-title {
    margin-bottom: 10px;
  }
  
  .delinquent-checks-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 350px;
  }
  
  .chart-container {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .site-list {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: auto;
    padding: 0 10px;
  }
  
  .site-label {
    margin: 5px 0;
    font-size: 16px;
    font-weight: bold;
    text-align: left;
  }
  
  @media (max-width: 768px) {
    .delinquent-checks-content {
      flex-direction: column;
      height: auto;
    }
  
    .chart-container {
      width: 100%;
      margin-bottom: 20px;
    }
  
    .site-list {
      width: 100%;
      height: auto;
      max-height: 200px;
    }
  }
}


// SupportHalfPieChart.css
.support-half-pie-chart {
  padding: 10px;
  background-color: transparent;
  width: 100%;
  // max-width: 600px;
  border: 2px solid black;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.support-half-pie-chart h4 {
  margin-bottom: 10px;
}

.chart-content {
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.chart-container {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.label-list {
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto;
  padding: 0 10px;
}

.label-item {
  margin: 5px 0;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
}

@media (max-width: 768px) {
  .support-half-pie-chart {
    max-width: 100%;
  }

  .chart-content {
    flex-direction: column;
    height: auto;
  }

  .chart-container {
    width: 100%;
    margin-bottom: 20px;
  }

  .label-list {
    width: 100%;
    max-height: 200px;
  }
}




// AedOwnedPieChart.css
.aed-owned-pie-chart {
  padding: 10px;
  background-color: transparent;
  width: 100%;
  // max-width: 600px;
  border: 2px solid black;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.aed-owned-pie-chart h4 {
  margin-bottom: 10px;
}

.chart-content {
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.chart-container {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.label-list {
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto;
  padding: 0 10px;
}

.label-item {
  margin: 5px 0;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
}

@media (max-width: 768px) {
  .aed-owned-pie-chart {
    max-width: 100%;
  }

  .chart-content {
    flex-direction: column;
    height: auto;
  }

  .chart-container {
    width: 100%;
    margin-bottom: 20px;
  }

  .label-list {
    width: 100%;
    max-height: 200px;
  }
}



// AedDistributionPieChart.css
.aed-distribution-pie-chart {
  padding: 10px;
  background-color: transparent;
  width: 100%;
  // max-width: 600px;
  border: 2px solid black;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.aed-distribution-pie-chart h4 {
  margin-bottom: 10px;
}

.chart-content {
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.chart-container {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.label-list {
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto;
  padding: 0 10px;
}

.label-item {
  margin: 5px 0;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
}

@media (max-width: 768px) {
  .aed-distribution-pie-chart {
    max-width: 100%;
  }

  .chart-content {
    flex-direction: column;
    height: auto;
  }

  .chart-container {
    width: 100%;
    margin-bottom: 20px;
  }

  .label-list {
    width: 100%;
    max-height: 200px;
  }
}